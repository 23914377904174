<template>
  <div class="page-container">
    <div class="card-container">
      <div class="header">
        <img :src="logoIcon" width="98" alt="" class="logo" />
        <div class="title">{{ translateTitle("安全验证") }}</div>
        <div class="send-type">
          {{
            translateTitle(
              $route.query.email ? "验证码发送至邮箱" : "验证码发送至手机"
            )
          }}
        </div>
        <div class="account">
          {{ $route.query.email ? handleEmail() : handleMobile() }}
        </div>
      </div>
      <div class="form">
        <el-form>
          <el-form-item
            :label="translateTitle('手机验证')"
            v-if="$route.query.mobile"
          >
            <el-input
              v-model="verifyCode"
              type="tel"
              maxlength="6"
              @input="autoRegister"
            >
              <template #suffix>
                <span
                  class="send-code-btn"
                  v-if="countDown === 0 || countDown === -1 || !isSendCode"
                  @click="getPhoneVerifyCode"
                  >{{ translateTitle("获取验证码") }}</span
                >
                <el-popover
                  v-else
                  v-model="showPopover"
                  popper-class="time-popover"
                  trigger="click"
                  placement="bottom-end"
                >
                  <div class="countdown-tip">
                    {{
                      translateTitle("@秒后重新发送验证码有效期为30分钟", [
                        countDown,
                      ])
                    }}
                  </div>
                  <template #reference>
                    <div class="send-success">
                      <span class="code-text">{{
                        translateTitle("验证码已发送")
                      }}</span>
                      <img :src="warnIcon" width="15" alt="" />
                    </div>
                  </template>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="translateTitle('邮箱验证')"
            v-else-if="$route.query.email"
          >
            <el-input
              v-model="verifyCode"
              type="tel"
              maxlength="6"
              @input="autoRegister"
            >
              <template #suffix>
                <span
                  class="send-code-btn"
                  v-if="countDown === 0 || countDown === -1 || !isSendCode"
                  @click="getEmailVerifyCode"
                  >{{ translateTitle("获取验证码") }}</span
                >
                <el-popover
                  v-else
                  v-model="showEmailPopover"
                  popper-class="time-popover"
                  trigger="click"
                  placement="bottom-end"
                >
                  <div class="countdown-tip">
                    {{
                      translateTitle("@秒后重新发送验证码有效期为30分钟", [
                        countDown,
                      ])
                    }}
                  </div>
                  <template #reference>
                    <div class="send-success">
                      <span class="code-text">{{
                        translateTitle("验证码已发送")
                      }}</span>
                      <img :src="warnIcon" width="15" alt="" />
                    </div>
                  </template>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <el-button class="next-btn" type="primary" @click="onRegister">
          <span v-if="!loading">{{ translateTitle("下一步") }}</span>
          <Loading v-else />
        </el-button>

        <div class="other-btn" @click="showNotCodeDialog = true">
          {{ translateTitle("未收到验证码 ?") }}
        </div>
      </div>
    </div>

    <NotCodeDialog
      :visible="showNotCodeDialog"
      @input="val => (showNotCodeDialog = val)"
      :isMobile="!!$route.query.mobile"
    />
  </div>
</template>

<script>
import logoIcon from "@/assets/img/users/logo.png";
import checkIcon from "@/assets/img/users/check.png";
import googleIcon from "@/assets/img/users/google.png";
import mobileIcon from "@/assets/img/users/mobile.png";
import emailIcon from "@/assets/img/users/email.png";
import { UCenterRequest } from "@/model/UCenterService";
import warnIcon from "@/assets/img/users/warn.png";
import Loading from "@/components/common/Loading.vue";
import NotCodeDialog from "./not-code.vue";
export default {
  components: {
    Loading,
    NotCodeDialog,
  },
  data() {
    return {
      logoIcon,
      checkIcon,
      warnIcon,
      countDown: -1,
      loading: false,
      verifyCode: "",
      showSetPassword: false,
      showPopover: true,
      isSendCode: false,
      tokenRegister: this.$route.query.tokenRegister,
      area: this.$route.query.area || "",
      showNotCodeDialog: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    authInfo() {
      const authInfo = this.$route.query.authInfo || {};
      return authInfo;
    },
    accountInfo() {
      const accountInfo = this.$route.query.accountInfo || {};
      return accountInfo;
    },
    allVerifyList() {
      return [
        {
          label: this.translateTitle("谷歌验证"),
          icon: googleIcon,
          type: "google",
        },
        {
          label: this.translateTitle("手机验证"),
          icon: mobileIcon,
          type: "phone",
        },
        {
          label: this.translateTitle("邮箱验证"),
          icon: emailIcon,
          type: "email",
        },
      ];
    },
    needVerifyList() {
      const authInfo = this.$route.query.authInfo || {};
      const list = this.allVerifyList.filter(item => {
        if (item.type === "google" && authInfo.open_google === 0) return false;
        if (item.type === "phone" && authInfo.open_mobile === 0) return false;
        if (item.type === "email" && authInfo.open_email === 0) return false;
        return true;
      });

      return list.slice(0, this.minVerifyNum);
    },
    requestType() {
      // 类型:
      // 0-未知
      // 1-绑定GOOGLE|2-换绑GOOGLE
      // 3-绑定手机|4-换绑手机
      // 5-绑定邮箱|6-换绑邮箱
      // 7-绑定通行密钥|8-删除通行密钥
      // 9-关闭GOOGLE|10-开启GOOGLE
      // 11-登陆|12-登陆密码修改|13-账号注销|14-找回密码
      // 21-提币|22-开启支付二次验证|23-关闭支付二次验证|24-设置支付密码|25-修改支付密码|26-修改二次验证限额|27-重置交易密码
      // 31-删除设备|32-关闭设备
      let type = 0;
      const source = this.$route.query.source;
      if (source === "bindGoogle") {
        type = 1;
      } else if (source === "editGoogle") {
        type = 2;
      } else if (source === "bindMobile") {
        type = 3;
      } else if (source === "editMobile") {
        type = 4;
      } else if (source === "bindEmail") {
        type = 5;
      } else if (source === "editEmail") {
        type = 6;
      } else if (source === "closeGoogle") {
        type = 9;
      } else if (source === "openGoogle") {
        type = 10;
      } else if (source === "login") {
        type = 11;
      } else if (source === "editPassword") {
        type = 12;
      } else if (source === "deleteAccount") {
        type = 13;
      } else if (source === "forgetPassword") {
        type = 14;
      } else if (source === "setPaymentPassword") {
        type = 24;
      } else if (source === "editPaymentPassword") {
        type = 25;
      } else if (source === "resetPaymentPassword") {
        type = 27;
      }
      return type;
    },
  },
  created() {
    console.log("params", this.$route.query);
  },
  methods: {
    handleCountDown() {
      this.countDown = 60;
      const timer = () => {
        this.countDownTimer = setTimeout(() => {
          this.countDown--;
          if (this.countDown > 0) {
            timer();
          } else {
            this.countDownTimer && clearTimeout(this.countDownTimer);
            this.countDown = -1;
            this.showPopover = false;
          }
        }, 1000);
      };
      timer();
    },

    handleMobile() {
      console.log("this.$route.query", this.$route.query);
      const { mobile } = this.$route.query;
      return mobile ? mobile.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2") : "";
    },
    handleEmail() {
      const { email } = this.$route.query;
      console.log("email", email);
      return email ? email.replace(/(\w{1}).*(\w{1})@(.*)/, "$1***$2@$3") : "";
    },
    async getEmailVerifyCode() {
      if (this.countDown > 0) return;
      this.handleCountDown();
      this.isSendCode = false;
      const res = await UCenterRequest.getRegisterEmailCode({
        email: this.$route.query.email,
        token_register: this.tokenRegister,
      });
      this.isSendCode = true;
      if (res.status === 200) {
        this.$nextTick(() => {
          this.showPopover = true;
          setTimeout(() => {
            this.showPopover = false;
          }, 3000);
        });
      } else {
        this.countDownTimer && clearTimeout(this.countDownTimer);
        this.countDown = -1;
      }
    },
    async getPhoneVerifyCode() {
      if (this.countDown > 0) return;
      this.handleCountDown();
      this.isSendCode = false;
      const res = await UCenterRequest.getRegisterPhoneCode({
        area: this.area[0] === "+" ? this.area.substring(1) : this.area,
        mobile: this.$route.query.mobile,
        token_register: this.tokenRegister,
      });
      this.isSendCode = true;
      if (res.status === 200) {
        this.$nextTick(() => {
          this.showPopover = true;
          setTimeout(() => {
            this.showPopover = false;
          }, 3000);
        });
      } else {
        this.countDownTimer && clearTimeout(this.countDownTimer);
        this.countDown = -1;
      }
    },
    getVerifyCode() {
      if (this.isPhone) {
        this.getPhoneVerifyCode();
      } else {
        this.getEmailVerifyCode();
      }
    },
    autoRegister(val) {
      if (val.length === 6) {
        setTimeout(() => {
          this.onRegister();
        }, 500);
      }
    },
    async onRegister() {
      if (!this.verifyCode) {
        this.$toast(this.translateTitle("请输入验证码"));
        return;
      }

      if (this.loading) return;

      this.loading = true;

      if (this.$route.query.mobile) {
        const res = await UCenterRequest.checkMobileCode({
          area: this.area[0] === "+" ? this.area.substring(1) : this.area,
          mobile: this.$route.query.mobile,
          mobile_code: this.verifyCode,
          token_register: this.tokenRegister,
        });
        if (res.status === 200) {
          this.$router.push({
            name: "RegisterSetPassword",
            query: {
              ...this.$route.query,
            },
          });
        } else {
          this.verifyCode = "";
        }
      } else {
        const res = await UCenterRequest.checkEmailCode({
          email: this.$route.query.email,
          email_code: this.verifyCode,
          token_register: this.tokenRegister,
        });
        if (res.status === 200) {
          this.$router.push({
            name: "RegisterSetPassword",
            query: {
              ...this.$route.query,
            },
          });
        } else {
          this.verifyCode = "";
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/common.scss";
.account {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-top: 12px;
}
.card-container {
  height: auto !important;
  min-height: 520px;
}
.next-btn {
  margin-top: 78px !important;
}
.other-btn {
  text-align: left !important;
}
.tip {
  margin-top: 32px;
  color: var(---c_t02, #5c5f69);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}
.step {
  color: #0d0e0e;

  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
  span {
    color: var(--7-c_m, #3348f6);
  }
}

.verify-item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: rgba(52, 106, 245, 0.06);
  width: 335px;
  height: 68px;
  margin-bottom: 12px;
  padding: 0 20px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  span {
    margin-left: 10px;
    margin-right: auto;
    color: var(--7-c_t01, #0d0e0e);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 131.25% */
  }
}
.verify-list {
  /* padding: 10px 20px; */
  ::v-deep {
    .el-dropdown-menu__item {
      margin-block-start: 12px;
      &:hover {
        background-color: unset;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.send-type {
  color: var(---c_t02, #5c5f69);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 33px;
}
.account {
  margin-top: 8px;
}
</style>

<style lang="scss">
.time-popover {
  border-radius: 6px;
  background: rgba(69, 69, 69, 0.86);
  box-shadow: none !important;
  transform: translateX(10px);

  .countdown-tip {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    max-width: 233px;
  }
  .popper__arrow {
    border-bottom-color: transparent;
    filter: none !important;
    left: calc(100% - 22px) !important;

    &::after {
      top: 0 !important;
      border-bottom-color: rgba(69, 69, 69, 0.86) !important;
    }
  }
}
</style>
